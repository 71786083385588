<template>
  <div>
    <h1>Afficher et remplir un formulaire PDF</h1>
    <input type="text" v-model="pdfUrl" placeholder="URL du fichier PDF" />
    <button @click="chargerPDF">Charger le PDF</button>
    <br />
    <button @click="remplirPDF">Remplir le formulaire</button>
    <br /><br />
    <iframe ref="pdfViewer" :src="pdfUrl" width="100%" height="600px"></iframe>
  </div>
</template>

<script>
import { PDFDocument } from 'pdf-lib';

export default {
  data() {
    return {
      pdfUrl: '',
      pdfDoc: null,
      formData: {}
    };
  },
  methods: {
    async chargerPDF() {
      try {
        this.pdfDoc = await PDFDocument.load(await fetch(this.pdfUrl).then(res => res.arrayBuffer()));
      } catch (error) {
        console.error('Erreur lors du chargement du PDF :', error);
      }
    },
    async remplirPDF() {
      if (!this.pdfDoc) {
        console.error('Aucun PDF chargé');
        return;
      }

      // const form = this.pdfDoc.getForm();
      // Object.keys(this.formData).forEach(fieldName => {
      //   const fieldValue = this.formData[fieldName];
      //   const field = form.getFieldsOfType('TextField').find(f => f.getName() === fieldName);
      //   if (field) {
      //     field.setText(fieldValue);
      //   }
      // });

      const pdfBytes = await this.pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const formData = new FormData();
      formData.append('file', blob);
      // Envoyez le fichier PDF rempli via votre API
    }
  }
};
</script>
